import { render, staticRenderFns } from "./relatorio_local.vue?vue&type=template&id=09e05a79&scoped=true&"
import script from "./relatorio_local.vue?vue&type=script&lang=js&"
export * from "./relatorio_local.vue?vue&type=script&lang=js&"
import style0 from "./relatorio_local.vue?vue&type=style&index=0&id=09e05a79&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e05a79",
  null
  
)

export default component.exports