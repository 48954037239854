<template>
  <div class="w-full">
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="flex-end"
      vs-align="flex-end"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="flex-end"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <el-select
          filterable
          clearable
          size="small"
          remote
          :remote-method="filtroLocal"
          :loading="loading"
          placeholder="Pesquise"
          loading-text="Buscando..."
          style="width: 270px"
          v-model="filtro.local"
          :popper-append-to-body="false"
          class="mx-1 mt-2"
        >
          <el-option
            v-for="local in locais"
            :value="local.id"
            :label="local.nome"
            :key="local.id"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="filtrar()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      loading: false,
      locais: Array,
      filtro: { dataDe: null, dataAte: null , local: null},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
    }
  },
  methods: {
    async filtrar () {
      await this.$emit("atualizarFiltro", this.filtro)
    },
    async mountData () {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0]
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0]
      this.filtro.dataDe = firstDay
      this.filtro.dataAte = lastDay
    },
    async getLocalByQuery (query) {
      try {
        this.locais = await this.$http.post(`getLocalByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroLocal (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getLocalByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
  },
  async mounted () {
    await this.mountData()
    await this.filtrar()
  },
  components: {
    ...components,
  },
};
</script>
